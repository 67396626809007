exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-animal-health-tsx": () => import("./../../../src/pages/animal-health.tsx" /* webpackChunkName: "component---src-pages-animal-health-tsx" */),
  "component---src-pages-food-feed-safety-tsx": () => import("./../../../src/pages/food-feed-safety.tsx" /* webpackChunkName: "component---src-pages-food-feed-safety-tsx" */),
  "component---src-pages-human-health-tsx": () => import("./../../../src/pages/human-health.tsx" /* webpackChunkName: "component---src-pages-human-health-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

